import React, { useEffect, useRef, useState } from "react"
import Section, { TextSection, ContainerSection, DoubleSection, CardSection, BackgroundSection } from '../components/Section'


export default ( { banners, bannerStyle = "style2" } ) => {
    return banners ? ( 
        <>
            {banners.map( banner => {
                bannerStyle =  bannerStyle === "style2" ? "style1" : "style 2"
                if( banner.component === "banner.regular-banner" )
                    return ( <Section id={banner.sectionId} style={bannerStyle} title={banner.title} description={banner.text} buttons={banner.buttons} image={banner.image} /> )
                else if( banner.component == 'banner.background-image-banner' )
                    return ( <BackgroundSection id={banner.sectionId} style={bannerStyle} title={banner.title} description={banner.text} buttons={banner.buttons} image={banner.image} /> )
                else if( banner.component === "banner.text-banner" )
                    return ( <TextSection id={banner.sectionId} style={bannerStyle} title={banner.title} description={banner.text} buttons={banner.buttons} roundedImage={banner.roundedImage} /> )
                else if( banner.component === "banner.content-banner" )
                    return ( <CardSection id={banner.sectionId} style={bannerStyle} title={banner.title} button={banner.buttons.length > 0 && banner.buttons[0]} cards={banner.cards} /> )
                else if( banner.component === "banner.image-banner" )
                    return ( 
                        <div data-aos='fade-in' data-aos-duration='1000' className='major-event-banner'>
                            <a href={banner.link} style={{ textDecoration: 'none' }}>
                                <img className={banner.full_width ? 'event-banner-img full-width fit full' : 'event-banner-img information fit'} src={banner.image} />
                            </a> 
                        </div>
                    )
            } )}
        </>
    ) : null
}
